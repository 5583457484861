import {
  Add,
  AddOutlined,
  RemoveOutlined,
  ShoppingCart,
  ShoppingCartOutlined,
} from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Button, IconButton, Stack, Typography, Divider } from "@mui/material";
import { Box, border } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import "./Cart.css";

// Definition of Data Structures used
/**
 * @typedef {Object} Product - Data on product available to buy
 *
 * @property {string} name - The name or title of the product
 * @property {string} category - The category that the product belongs to
 * @property {number} cost - The price to buy the product
 * @property {number} rating - The aggregate rating of the product (integer out of five)
 * @property {string} image - Contains URL for the product image
 * @property {string} _id - Unique ID for the product
 */

/**
 * @typedef {Object} CartItem -  - Data on product added to cart
 *
 * @property {string} name - The name or title of the product in cart
 * @property {string} qty - The quantity of product added to cart
 * @property {string} category - The category that the product belongs to
 * @property {number} cost - The price to buy the product
 * @property {number} rating - The aggregate rating of the product (integer out of five)
 * @property {string} image - Contains URL for the product image
 * @property {string} productId - Unique ID for the product
 */

/**
 * Returns the complete data on all products in cartData by searching in productsData
 *
 * @param { Array.<{ productId: String, qty: Number }> } cartData
 *    Array of objects with productId and quantity of products in cart
 *
 * @param { Array.<Product> } productsData
 *    Array of objects with complete data on all available products
 *
 * @returns { Array.<CartItem> }
 *    Array of objects with complete data on products in cart
 *
 */
export const generateCartItemsFrom = (cartData, productsData) => {
  const cartProducts = [];
  cartData.forEach((cart) => {
    let data = productsData.filter((pd) => pd["_id"] === cart.productId)[0];
    cartProducts.push({ ...data, qty: cart.qty });
  });
  return cartProducts;
};

/**
 * Get the total value of all products added to the cart
 *
 * @param { Array.<CartItem> } items
 *    Array of objects with complete data on products added to the cart
 *
 * @returns { Number }
 *    Value of all items in the cart
 *
 */
export const getTotalCartValue = (items = []) => {
  return items.reduce((prev, curr) => prev + curr.qty * curr.cost, 0);
};

// TODO: CRIO_TASK_MODULE_CHECKOUT - Implement function to return total cart quantity
/**
 * Return the sum of quantities of all products added to the cart
 *
 * @param { Array.<CartItem> } items
 *    Array of objects with complete data on products in cart
 *
 * @returns { Number }
 *    Total quantity of products added to the cart
 *
 */
export const getTotalItems = (items = []) => {
  return items.reduce((acc, curr) => acc + curr.qty, 0);
};

// TODO: CRIO_TASK_MODULE_CHECKOUT - Add static quantity view for Checkout page cart
/**
 * Component to display the current quantity for a product and + and - buttons to update product quantity on cart
 *
 * @param {Number} value
 *    Current quantity of product in cart
 *
 * @param {Function} handleAdd
 *    Handler function which adds 1 more of a product to cart
 *
 * @param {Function} handleDelete
 *    Handler function which reduces the quantity of a product in cart by 1
 *
 * @param {Boolean} isReadOnly
 *    If product quantity on cart is to be displayed as read only without the + - options to change quantity
 *
 */
const ItemQuantity = ({ value, handleAdd, handleDelete, isReadOnly, isMobileCheckout }) => {
  return (
    <>
      <Stack className="itemQuantity-desktop" direction="row" alignItems="center">
        {!isReadOnly && (
          <IconButton size="small" color="primary" onClick={handleDelete}>
            <RemoveOutlined />
          </IconButton>
        )}
        <Box textAlign="center" fontSize="1.25rem" paddingX="1rem" data-testid="item-qty">
          {!isReadOnly ? value : "Qty: " + value}
        </Box>
        {!isReadOnly && (
          <IconButton size="small" color="primary" onClick={handleAdd}>
            <AddOutlined />
          </IconButton>
        )}
      </Stack>
      {!isMobileCheckout ? <Stack sx={{ border: 1, borderColor: '#7a7878' }} className="itemQuantity-mobile" direction="row" alignItems="center">
        {!isReadOnly && (
          <IconButton size="small" color="primary" onClick={handleDelete}>
            <RemoveOutlined />
          </IconButton>
        )}
        <Divider orientation="vertical" flexItem />
        <Box textAlign="center" fontSize="1.25rem" paddingX="1rem" data-testid="item-qty">
          {!isReadOnly ? value : "Qty: " + value}
        </Box>
        <Divider orientation="vertical" flexItem />
        {!isReadOnly && (
          <IconButton size="small" color="primary" onClick={handleAdd}>
            <AddOutlined />
          </IconButton>
        )}
      </Stack>:
        <Box textAlign='end' fontSize="1.25rem"  data-testid="item-qty">
          {!isReadOnly ? value : "Qty: " + value}
        </Box>
        }
    </>
  );
};

/**
 * Component to display the Cart view
 *
 * @param { Array.<Product> } products
 *    Array of objects with complete data of all available products
 *
 * @param { Array.<Product> } items
 *    Array of objects with complete data on products in cart
 *
 * @param {Function} handleQuantity
 *    Current quantity of product in cart
 *
 * @param {Boolean} isReadOnly
 *    If product quantity on cart is to be displayed as read only without the + - options to change quantity
 *
 */
const Cart = ({ products, items = [], handleQuantity, isReadOnly, isMobileCheckout }) => {
  const [cartItems, setCartItems] = useState([]);
  const [mobileCartExpanded, setMobileCartExpanded] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setCartItems(generateCartItemsFrom(items, products));
  }, [items, products]);

  const handleAdd = (item) => {
    // console.log(item);
    handleQuantity(undefined, items, products, item._id, item.qty + 1, true);
  };
  const handleDelete = (item) => {
    handleQuantity(undefined, items, products, item._id, item.qty - 1, true);
  };
  const handleInfoClick = () => {
    setMobileCartExpanded(!mobileCartExpanded);
  }

  if (!items.length) {
    return (
      <Box className="cart empty">
        <ShoppingCartOutlined className="empty-cart-icon" />
        <Box color="#aaa" textAlign="center">
          Cart is empty. Add more items to the cart to checkout.
        </Box>
      </Box>
    );

  }

  return (
    <>
      <Box className="desktop">
        <Box className="cart">
          {
            /* TODO: CRIO_TASK_MODULE_CART - Display view for each cart item with non-zero quantity */
            cartItems.map((item) => {
              return (
                <Box
                  key={item._id}
                  display="flex"
                  alignItems="flex-start"
                  padding="1rem"
                >
                  <Box className="image-container">
                    <img
                      // Add product image
                      src={item.image}
                      // Add product name as alt eext
                      alt={item.name}
                      width="100%"
                      height="100%"
                    />
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="space-between"
                    height="6rem"
                  >
                    <div>{/* Add product name */ item.name}</div>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <ItemQuantity
                        // Add required props by checking implementation
                        value={item.qty}
                        productId={item.productId}
                        handleAdd={() => handleAdd(item)}
                        handleDelete={() => handleDelete(item)}
                        isReadOnly={isReadOnly}
                      />
                      <Box padding="0.5rem" fontSize='1.25rem' fontWeight="500">
                        ${/* Add product cost */ item.qty * item.cost}
                      </Box>
                    </Box>
                  </Box>
                </Box>
              );
            })
          }

          <Box
            padding="1rem"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box color="#3C3C3C" alignSelf="center">
              Order total
            </Box>
            <Box
              color="#3C3C3C"
              fontWeight="700"
              fontSize="1.5rem"
              alignSelf="center"
              data-testid="cart-total"
            >
              ${getTotalCartValue(cartItems)}
            </Box>
          </Box>

          {!isReadOnly && <Box display="flex" justifyContent="flex-end" className="cart-footer">
            <Button
              color="primary"
              variant="contained"
              startIcon={<ShoppingCart />}
              className="checkout-btn"
              onClick={() => history.push("/checkout")}
            >
              Checkout
            </Button>
          </Box>}
        </Box>
        {isReadOnly && <Box className="cart">
          <Box
            padding="1rem"
            display="flex"
            flexDirection="column"

          >
            <Box color="#3C3C3C" fontWeight="700"
              fontSize="1.5rem" alignSelf="start">
              Order Details
            </Box>
            <Box
              paddingY="1rem"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box color="#3C3C3C" alignSelf="center">
                Products
              </Box>
              <Box
                color="#3C3C3C"
                alignSelf="center"
                data-testid="cart-total"
              >
                {getTotalItems(items)}
              </Box>
            </Box>
            <Box
              paddingY="1rem"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box color="#3C3C3C" alignSelf="center">
                Subtotal
              </Box>
              <Box
                color="#3C3C3C"
                alignSelf="center"
                data-testid="cart-total"
              >
                ${getTotalCartValue(cartItems)}
              </Box>
            </Box>
            <Box
              paddingY="1rem"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box color="#3C3C3C" alignSelf="center">
                Shipping charges
              </Box>
              <Box
                color="#3C3C3C"
                alignSelf="center"
                data-testid="cart-total"
              >
                $0
              </Box>
            </Box>
            <Box
              paddingY="1rem"
              display="flex"
              fontWeight="700"
              fontSize="1.25rem"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box color="#3C3C3C" alignSelf="center">
                Total
              </Box>
              <Box
                color="#3C3C3C"
                alignSelf="center"
                data-testid="cart-total"
              >
                ${getTotalCartValue(cartItems)}
              </Box>
            </Box>
          </Box>
        </Box>}
      </Box>
      {
        isMobileCheckout && 
        <div className="checkout_mob">
        <Box display="flex" justifyContent="space-between" padding='1rem'>
          <Typography><Typography variant="h5" component="span">{items.length + ' '}</Typography>{items.length == 1 ? ' item' : ' items'} in cart </Typography>
        </Box>
        {cartItems.map((item) => {
          return (
            <Box paddingY="1rem">
              <Box
                key={item._id}
                display="flex"
                alignItems="flex-start"
                justifyContent="space-between"
                paddingX="1rem"
              >
                <Box className="image-container">
                  <img
                    // Add product image
                    src={item.image}
                    // Add product name as alt eext
                    alt={item.name}
                    width="100%"
                    height="100%"
                  />
                </Box>
                <Box

                  display="flex"
                  flexDirection="column"
                  alignItems="end"
                  paddingX="1rem"
                >
                  <div>{/* Add product name */ item.name}</div>
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <Stack>
                      <Box padding="0.5rem">
                        <ItemQuantity
                          // Add required props by checking implementation
                          value={item.qty}
                          productId={item.productId}
                          handleAdd={() => handleAdd(item)}
                          handleDelete={() => handleDelete(item)}
                          isReadOnly={isReadOnly}
                          isMobileCheckout={isMobileCheckout}
                        />
                      </Box>
                      <Box paddingBottom="0.5rem" fontWeight="500" fontSize="1.25rem" textAlign="end" >
                        ${/* Add product cost */ item.qty * item.cost}
                      </Box>
                    </Stack>
                  </Box>
                </Box>
              </Box>
              <Divider />
            </Box>

          );
        })
        }
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          paddingX='1.5rem'
          paddingBottom='1rem'
        >
          <Typography fontWeight="700" >Total amount to checkout</Typography>
          <Typography fontWeight="700" fontSize="1.25rem" textAlign="end">${getTotalCartValue(cartItems)}</Typography>
        </Stack>
        <Divider />

      </div>
      }
      {!isMobileCheckout && <Box className="mobile">
        {mobileCartExpanded &&
          <>
            <Box display="flex" justifyContent="space-between" className="cart-footer">
              <Typography><Typography variant="h5" component="span">{items.length + ' '}</Typography>{items.length == 1 ? ' item' : ' items'} in cart </Typography>
              <IconButton onClick={handleInfoClick}>
                <CloseIcon />
              </IconButton>
            </Box>

            {cartItems.map((item) => {
              return (
                <Box paddingY="1rem">
                  <Box
                    key={item._id}
                    display="flex"
                    alignItems="flex-start"
                    justifyContent="space-between"
                    paddingX="1rem"
                  >
                    <Box className="image-container">
                      <img
                        // Add product image
                        src={item.image}
                        // Add product name as alt eext
                        alt={item.name}
                        width="100%"
                        height="100%"
                      />
                    </Box>
                    <Box

                      display="flex"
                      flexDirection="column"
                      alignItems="end"
                      paddingX="1rem"
                    >
                      <div>{/* Add product name */ item.name}</div>
                      <Box
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <Stack>
                          <Box padding="0.5rem">
                            <ItemQuantity
                              // Add required props by checking implementation
                              value={item.qty}
                              productId={item.productId}
                              handleAdd={() => handleAdd(item)}
                              handleDelete={() => handleDelete(item)}
                              isReadOnly={isReadOnly}

                            />
                          </Box>
                          <Box paddingBottom="0.5rem" fontWeight="500" fontSize="1.25rem" textAlign="end" >
                            ${/* Add product cost */ item.qty * item.cost}
                          </Box>
                        </Stack>
                      </Box>
                    </Box>
                  </Box>
                  <Divider />
                </Box>

              );
            })
            }
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              paddingX='1.5rem'
              paddingBottom='1rem'
            >
              <Typography fontWeight="700" >Total amount to checkout</Typography>
              <Typography fontWeight="700" fontSize="1.25rem" textAlign="end">${getTotalCartValue(cartItems)}</Typography>
            </Stack>
            <Divider />

          </>
        }
        {!isReadOnly &&  <Box display="flex" justifyContent="space-between" className="cart-footer">
          {!mobileCartExpanded ? <Stack>
            <Typography>{items.length}{' '} {items.length == 1 ? 'item' : 'items'} in cart <IconButton size="small" onClick={handleInfoClick}>
              <InfoOutlinedIcon />
            </IconButton></Typography>
            <Typography variant="h5">${getTotalCartValue(cartItems)}</Typography>
          </Stack> : <Box height="4.1rem" />}
          <Button
            color="primary"
            size="medium"
            variant="contained"
            startIcon={<ShoppingCart />}
            className="checkout-btn"
            onClick={() => history.push("/checkout")}
          >
            Checkout
          </Button>
        </Box>}
      </Box>}
    </>);

};

export default Cart;
