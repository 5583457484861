import { AddShoppingCartOutlined } from "@mui/icons-material";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Rating,
  Typography,
} from "@mui/material";
import React from "react";
import "./ProductCard.css";

const ProductCard = ({ product, handleAddToCart, items, products }) => {
  return (
    <Card className="card">
      <CardMedia
          component="img"
          height="200"
          image={product.image}
          alt={product.name}
        />
        <CardContent>
          <Typography variant="body1" color="text.primary">
            {product.name}
          </Typography>
          <Typography gutterBottom variant="h5" component="div">
            ${product.cost}
          </Typography>
          <Rating name="read-only" value={product.rating} precision={0.5} readOnly />
        </CardContent>
        <Button fullWidth variant="contained" onClick={()=>{
          handleAddToCart(undefined,items,products,product._id,1,false);
        }}><AddShoppingCartOutlined/>ADD TO CART</Button>
    </Card>
  );
};

export default ProductCard;
