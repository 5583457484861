import { Search, SentimentDissatisfied } from "@mui/icons-material";
import ProductCard from "./ProductCard";
import Cart from "./Cart";
import {
  CircularProgress,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { config } from "../App";
import Footer from "./Footer";
import Header from "./Header";
import "./Products.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

/**
 * @typedef {Object} CartItem -  - Data on product added to cart
 *
 * @property {string} name - The name or title of the product in cart
 * @property {string} qty - The quantity of product added to cart
 * @property {string} category - The category that the product belongs to
 * @property {number} cost - The price to buy the product
 * @property {number} rating - The aggregate rating of the product (integer out of five)
 * @property {string} image - Contains URL for the product image
 * @property {string} _id - Unique ID for the product
 */

const Products = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [productList, setProductList] = useState([]);
  const [serachedProductList, setSearchedProductList] = useState([]);
  const [cartItems,setCartItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [debounceTimeout, setDebounceTimeout] = useState(0);
  const [noProduct, setNoProduct] = useState(false);
  const history = useHistory();


  // TODO: CRIO_TASK_MODULE_PRODUCTS - Fetch products data and store it
  /**
   * Make API call to get the products list and store it to display the products
   *
   * @returns { Array.<Product> }
   *      Array of objects with complete data on all available products
   *
   * API endpoint - "GET /products"
   *
   * Example for successful response from backend:
   * HTTP 200
   * [
   *      {
   *          "name": "iPhone XR",
   *          "category": "Phones",
   *          "cost": 100,
   *          "rating": 4,
   *          "image": "https://i.imgur.com/lulqWzW.jpg",
   *          "_id": "v4sLtEcMpzabRyfx"
   *      },
   *      {
   *          "name": "Basketball",
   *          "category": "Sports",
   *          "cost": 100,
   *          "rating": 5,
   *          "image": "https://i.imgur.com/lulqWzW.jpg",
   *          "_id": "upLK9JbQ4rMhTwt4"
   *      }
   * ]
   *
   * Example for failed response from backend:
   * HTTP 500
   * {
   *      "success": false,
   *      "message": "Something went wrong. Check the backend console for more details"
   * }
   */
  const performAPICall = async () => {
    try {
      setNoProduct(false)
      setLoading(true);
      let response = await axios.get(`${config.endpoint}/products`);
      setLoading(false);
      setProductList(response.data);
      setSearchedProductList(response.data)
    } catch (err) {
      setLoading(false);
      enqueueSnackbar(
        "Something went wrong. Check that the backend is running, reachable and returns valid JSON.",
        { variant: "error" }
      );
    }
  };
  useEffect(() => {
    performAPICall();
    const token = localStorage.getItem('token');
    if(token) fetchCart(token);
  }, []);

  // TODO: CRIO_TASK_MODULE_PRODUCTS - Implement search logic
  /**
   * Definition for search handler
   * This is the function that is called on adding new search keys
   *
   * @param {string} text
   *    Text user types in the search bar. To filter the displayed products based on this text.
   *
   * @returns { Array.<Product> }
   *      Array of objects with complete data on filtered set of products
   *
   * API endpoint - "GET /products/search?value=<search-query>"
   *
   */
  const performSearch = async (text) => {
    if (text === "") {
      performAPICall();
      return;
    }
    try {
      setNoProduct(false);
      setLoading(true);
      let response = await axios.get(
        `${config.endpoint}/products/search?value=${text}`
      );
      setSearchedProductList(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      if (err.response) {
        setNoProduct(true);
        setSearchedProductList([]);
      }
    }
  };

  // TODO: CRIO_TASK_MODULE_PRODUCTS - Optimise API calls with debounce search implementation
  /**
   * Definition for debounce handler
   * With debounce, this is the function to be called whenever the user types text in the searchbar field
   *
   * @param {{ target: { value: string } }} event
   *    JS event object emitted from the search input field
   *
   * @param {NodeJS.Timeout} debounceTimeout
   *    Timer id set for the previous debounce call
   *
   */
  const debounceSearch = (event, debounceTimeout) => {
    if (debounceTimeout !== 0) clearTimeout(debounceTimeout);
    setDebounceTimeout(
      setTimeout(() => performSearch(event.target.value), 500)
    );
    setSearch(event.target.value); //if we set the state earlier, the component will rerender and take the previous target value
  };

  /**
   * Perform the API call to fetch the user's cart and return the response
   *
   * @param {string} token - Authentication token returned on login
   *
   * @returns { Array.<{ productId: string, qty: number }> | null }
   *    The response JSON object
   *
   * Example for successful response from backend:
   * HTTP 200
   * [
   *      {
   *          "productId": "KCRwjF7lN97HnEaY",
   *          "qty": 3
   *      },
   *      {
   *          "productId": "BW0jAAeDJmlZCF8i",
   *          "qty": 1
   *      }
   * ]
   *
   * Example for failed response from backend:
   * HTTP 401
   * {
   *      "success": false,
   *      "message": "Protected route, Oauth2 Bearer token not found"
   * }
   */
  const fetchCart = async (token) => {
    if (!token) return;

    try {
      // TODO: CRIO_TASK_MODULE_CART - Pass Bearer token inside "Authorization" header to get data from "GET /cart" API and return the response data
      const {data} = await axios.get(`${config.endpoint}/cart`,{
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      setCartItems(data);
    } catch (e) {
      if (e.response) {
        enqueueSnackbar(e.response.data.message, { variant: "error" });
        localStorage.clear();
        history.push('/')
      } else {
        enqueueSnackbar(
          "Could not fetch cart details. Check that the backend is running, reachable and returns valid JSON.",
          {
            variant: "error",
          }
        );
      }
      return null;
    }
  };

  // TODO: CRIO_TASK_MODULE_CART - Return if a product already exists in the cart
  /**
   * Return if a product already is present in the cart
   *
   * @param { Array.<{ productId: String, quantity: Number }> } items
   *    Array of objects with productId and quantity of products in cart
   * @param { String } productId
   *    Id of a product to be checked
   *
   * @returns { Boolean }
   *    Whether a product of given "productId" exists in the "items" array
   *
   */
  const isItemInCart = (items, productId) => {
    // console.log(productId);
    let pArray = items.filter(item=>item.productId===productId);
    // console.log(pArray);
    return pArray.length!==0;
  };

  /**
   * Perform the API call to add or update items in the user's cart and update local cart data to display the latest cart
   *
   * @param {string} token
   *    Authentication token returned on login
   * @param { Array.<{ productId: String, quantity: Number }> } items
   *    Array of objects with productId and quantity of products in cart
   * @param { Array.<Product> } products
   *    Array of objects with complete data on all available products
   * @param {string} productId
   *    ID of the product that is to be added or updated in cart
   * @param {number} qty
   *    How many of the product should be in the cart
   * @param {boolean} options
   *    If this function was triggered from the product card's "Add to Cart" button
   *
   * Example for successful response from backend:
   * HTTP 200 - Updated list of cart items
   * [
   *      {
   *          "productId": "KCRwjF7lN97HnEaY",
   *          "qty": 3
   *      },
   *      {
   *          "productId": "BW0jAAeDJmlZCF8i",
   *          "qty": 1
   *      }
   * ]
   *
   * Example for failed response from backend:
   * HTTP 404 - On invalid productId
   * {
   *      "success": false,
   *      "message": "Product doesn't exist"
   * }
   */
  const addToCart = async (
    token=localStorage.getItem('token'),
    items,
    products,
    productId,
    qty,
    fromCart,
    options = { preventDuplicate: false }
  ) => {
    if(!token){
      enqueueSnackbar('Please login and try again' ,{ variant: "warning" })
      return;
    }
    if(!fromCart && isItemInCart(items,productId)){
      enqueueSnackbar('Item already in cart. Use the cart sidebar to update quantity or remove item.',{variant: "warning"});
      return;
    }
    try{

      let {data} = await axios.post(`${config.endpoint}/cart`,{
        productId,
        qty,
      },{
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      // console.log(data);
      setCartItems(data);
      
    }catch(e){
      if (e.response) {
        enqueueSnackbar(e.response.data.message, { variant: "error" });
      } else {
        enqueueSnackbar(
          "Could not fetch cart details. Check that the backend is running, reachable and returns valid JSON.",
          {
            variant: "error",
          }
        );
      }
      return null;
    }
  };
  return (
    <div>
      <Header hasHiddenAuthButtons={false} setCartItems={setCartItems}>
        {/* TODO: CRIO_TASK_MODULE_PRODUCTS - Display search bar in the header for Products page */}
        <TextField
          className="search-desktop"
          size="small"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Search color="primary" />
              </InputAdornment>
            ),
          }}
          placeholder="Search for items/categories"
          name="search"
          value={search}
          onChange={(e) => debounceSearch(e, debounceTimeout)}
        />
      </Header>

      {/* Search view for mobiles */}

      <TextField
        className="search-mobile"
        size="small"
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Search color="primary" />
            </InputAdornment>
          ),
        }}
        placeholder="Search for items/categories"
        name="search"
        value={search}
        onChange={(e) => debounceSearch(e, debounceTimeout)}
      />
      <Grid container>
        <Grid item xs={12} md={9}>
          <Grid sx={{ mb: 2 }} rowSpacing={2} container>
            <Grid item className="product-grid">
              <Box className="hero">
                <p className="hero-heading">
                  India’s{" "}
                  <span className="hero-highlight">FASTEST DELIVERY</span> to
                  your door step
                </p>
              </Box>
            </Grid>
            <Grid item className="product-grid">
              {noProduct && (
                <Box className="loading">
                  <SentimentDissatisfied />
                  <Typography variant="h5">No products found</Typography>
                </Box>
              )}
              {loading ? (
                <Box className="loading">
                  <CircularProgress />
                  <Typography variant="h5">Loading Products...</Typography>
                </Box>
              ) : (
                <Grid sx={{ p: 2 }} container spacing={2}>
                  {serachedProductList.map((product) => {
                    return (
                      <Grid  key={product.id} item xs={6} md={3}>
                        <ProductCard  product={product} handleAddToCart={addToCart}
                        items={cartItems}
                        products={productList}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid sx={{ backgroundColor: "#E9F5E1" }} item xs={12} md={3}>
          <Cart products ={productList} items = {cartItems} handleQuantity={addToCart}/>
        </Grid>
      </Grid>
      <Footer />
    </div>
  );
};

export default Products;
