import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Avatar, Button, Stack } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import Box from "@mui/material/Box";
import React from "react";
import "./Header.css";

const Header = ({ children, hasHiddenAuthButtons,setCartItems }) => {
  const { enqueueSnackbar } = useSnackbar();
  const history= useHistory();
  const logout = ()=>{
    localStorage.clear();
    // window.location.reload();
    enqueueSnackbar("Logged out successfully", { variant: "success" })
    if(setCartItems) setCartItems([]);
    back();
  }
  const login=()=>{
    history.push('/login',{from: "Products"})
  }
  const register=()=>{
    history.push('/register',{from: "Products"})
  }
  const back=()=>{
    history.push('/',{from: window.location.from})
  }
  return (
    <Box className="header">
      <Box className="header-title" onClick={e=>{history.push('/',{from:window.location.from})}}>
        <img src="logo_light.svg" alt="QKart-icon"></img>
      </Box>
     {children}
      {hasHiddenAuthButtons && (
          <Button
            className="explore-button"
            startIcon={<ArrowBackIcon />}
            variant="text"
            onClick={back}
          >
            Back to explore
          </Button>
      )}
      {!hasHiddenAuthButtons && (
        <Stack direction="row" spacing={1}>
          {!localStorage.getItem("token") && (
            <>
              <Button onClick={login}>Login</Button>
              <Button onClick={register} variant="contained">Register</Button>
            </>
          )}
          {localStorage.getItem("token") && (
            <>
              
              <Stack direction="row" spacing={1}>
              <Stack direction="row" alignItems="center">
              <Avatar sx={{ marginRight: '5px' }} alt= {'/'+ localStorage.getItem('username')+'/i'} src="avatar.png" />
                <div style={{color:"#45c09f"}}>{localStorage.getItem('username')}</div>
              </Stack>
              <Button onClick={logout}>Logout</Button>
              </Stack>
            </>
          )}
        </Stack>
      )}
    </Box>
  );
};

export default Header;
